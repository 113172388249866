import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import Section from 'components/UI/Section/Section';
import Title from 'components/UI/Title/Title';
import Text from 'components/UI/Text/Text';

const Clients = () => (
  <Layout page="clients">
    <Section>
      <Title>
        <Text as="h2">Thank you for feedback!</Text>
      </Title>
    </Section>
  </Layout>
);

export default Clients;

export const query = graphql`
  query {
    allContentfulClients {
      edges {
        node {
          name
          logo { file { url } }
          industry
        }
      }
    }

    allContentfulPartners {
      edges {
        node {
          name
          logo { file { url } }
          industry
        }
      }
    }
  }
`;
